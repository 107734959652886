<div class="login flexbox direction-column centered default-gap"
     [ngClass]="{'handset' : (isHandset$ | async)}">
  <mat-card class="login-card custom-card semi-round shadow-1">
    <section class="header flexbox direction-column">
      <div class="eeule-logo flexbox centered direction-column">
        <img alt="logo" class="logo" ngSrc="./assets/images/eeule_logo_100x96.png" width="100" height="96" priority/>
        <img alt="logo-label" class="logo-label" ngSrc="./assets/images/eeule_schrift_logo_webview_120x38.png"
             width="120"
             height="38"/>
      </div>
      <h1 class="header-text secondary-text">
        {{ 'login.title' | translate }}
      </h1>
      <div class="sub-header primary">
        {{ 'login.subTitle' | translate }}
      </div>
    </section>
    <form class="login-form flexbox direction-column" [formGroup]="loginForm" (ngSubmit)="submit()">
      <div class="inputs flexbox direction-column">
        <mat-form-field class="mail w-100-percent no-background smaller-font">
          <mat-label>{{ 'controls.email' | translate }}</mat-label>
          <mat-icon matPrefix>email</mat-icon>
          <input matInput formControlName="email" type="email">
        </mat-form-field>
        <mat-form-field class="password w-100-percent no-background smaller-font">
          <mat-label>{{ 'controls.password' | translate }}</mat-label>
          <mat-icon matPrefix>lock</mat-icon>
          <input matInput formControlName="password" type="password">
        </mat-form-field>
        <mat-checkbox formControlName="persistentLogin" class="persistent-check" color="primary">
          <span class="secondary-text">{{ 'login.stayLoggedIn' | translate }}</span>
        </mat-checkbox>
      </div>

      @if ((_authService.loginMessageSubject$ | async)?.length) {
        <div class="login-message warn">
          {{ _authService.loginMessageSubject$ | async }}
        </div>
      }
      <button mat-flat-button color="primary" [disabled]="!loginForm.valid" class="rounded">
        <span class="button-label">{{ 'login.login' | translate }}</span>
      </button>
    </form>
    <a class="forgot-password secondary-text"
       (click)="openForgotPasswordDialog()"
       (keyup.enter)="openForgotPasswordDialog()"
       tabindex="0">
      {{ 'login.forgotPasswort' | translate }}
    </a>
    <section class="info">
      <div class="imprint secondary-text">{{ 'imprint' | translate }}</div>
      <div class="agb secondary-text">{{ 'termsAndConditions' | translate }}</div>
      <div class="privacy secondary-text">{{ 'privacyPolicy' | translate }}</div>
    </section>
  </mat-card>
</div>
