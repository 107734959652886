<mat-toolbar class="page-header" [ngClass]="{'handset' : isHandset$ | async}">
  @if (isHandset$ | async) {
    <button
      type="button"
      aria-label="Toggle sidenav"
      class="btn-toggle accent-2 no-shadow"
      mat-mini-fab
      (click)="_navigationService.toggle()">
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
  }

  <div class="eeule-logo">
    <img alt="logo" class="logo" ngSrc="./assets/images/eeule_logo_55x53.png" width="55" height="53"/>
    <img alt="logo-label" class="logo-label" ngSrc="./assets/images/eeule_schrift_logo_webview_95x30.png" width="95"
         height="30" priority/>
  </div>
  <div class="page-header-custom-content">
    <ng-content/>
  </div>
  @if ((isHandset$ | async) === false) {
    <eeule-admin-user-information trigger="page-header"/>
  }
</mat-toolbar>
