import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../services/auth/auth.service";
import {map, Observable, take} from "rxjs";

/**
 * Guard to protect routes based on authentication status.
 * @returns {Observable<boolean>} An observable indicating whether the route should be activated.
 */
export const authGuard: CanActivateFn = (): Observable<boolean> => {
  const _authService: AuthService = inject(AuthService);
  const _router: Router = inject(Router);
  return _authService.isAuthenticated().pipe(
    take(1),
    map((isAuthenticated) => {
      if (!isAuthenticated) {
        // Redirect to the login page or any other route
        _router.navigate(['/login'])
          .catch(error => console.error("error navigating back to login:", error));
        return false;
      }
      return true;
    })
  );
};
