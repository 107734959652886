import {Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {authGuard} from "./core/guards/auth.guard";
import {NotFoundComponent} from "./portal/not-found/not-found.component";
import {PortalComponent} from "./portal/portal.component";
import {redirectGuard} from "./core/guards/redirect.guard";

export const routes: Routes = [
  {
    path: 'login',
    canActivate: [redirectGuard],
    component: LoginComponent,
  },
  {
    path: 'portal',
    canActivate: [authGuard],
    component: PortalComponent,
    loadChildren: () => import('./portal/portal.routes').then(mod => mod.portalRoutes)
  },
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: '**', component: NotFoundComponent}
];
