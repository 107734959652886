<div class="user-information"
     [ngClass]="{'handset' : (isHandset$ | async)}">
  <section class="user-credentials" [matMenuTriggerFor]="menu">
    <div>
      @if((currentUser$ | async)?.firstName && (currentUser$ | async)?.lastName) {
        <span class="first-name">{{ (currentUser$ | async)?.firstName }} </span>
        <span class="last-name">{{ (currentUser$ | async)?.lastName }}</span>
      } @else {
        <span class="email">{{ (currentUser$ | async)?.email || '' }}</span>
      }
    </div>
    <div class="user-dropdown-icon">
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </section>
  <mat-menu #menu="matMenu" [class]="'user-settings-menu ' + trigger">
    <button mat-menu-item>{{ 'userMenu.myProfile' | translate }}</button>
    <button mat-menu-item>{{ 'userMenu.settings' | translate }}</button>
    <div class="theme-switcher">
      @if ((_themeService.themeSubject$ | async) === 'default-theme') {
        <mat-icon aria-label="theme" [matTooltip]="'tooltips.lightTheme' | translate" color="accent">light_mode</mat-icon>
      } @else {
        <mat-icon aria-label="theme" [matTooltip]="'tooltips.darkTheme' | translate" class="accent-2">dark_mode</mat-icon>
      }
      <mat-slide-toggle [checked]="(_themeService.themeSubject$ | async) === 'dark-theme'"
                        hideIcon
                        [matTooltip]="'tooltips.switchTheme' | translate"
                        (click)="$event.stopPropagation()"
                        (change)=" switchTheme($event)">
      </mat-slide-toggle>
    </div>
  </mat-menu>
  <section class="user-image">
    <img alt="user" ngSrc="../../../../assets/images/person-placeholder.png" height="54" width="54" priority>
  </section>
</div>
