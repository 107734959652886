<div class="main-navigation">
  <mat-nav-list
    class="portal"
    [ngClass]="(isHandset$ | async) ? 'mobile' : 'desktop'"
  >
    <a
      mat-list-item
      routerLink="./home"
      routerLinkActive="is-active"
      class="home"
    >
      <div class="nav-item-content">
        <mat-icon>dashboard</mat-icon>
        <div class="nav-label">
          {{ "mainNavigation.dashboard" | translate }}
        </div>
      </div>
    </a>
    <a
      mat-list-item
      routerLink="./users"
      routerLinkActive="is-active"
      class="users"
    >
      <div class="nav-item-content">
        <mat-icon>supervised_user_circle</mat-icon>
        <div class="nav-label">{{ "mainNavigation.users" | translate }}</div>
      </div>
    </a>
    <a
      mat-list-item
      routerLink="./finance"
      routerLinkActive="is-active"
      class="finance"
    >
      <div class="nav-item-content">
        <mat-icon>attach_money</mat-icon>
        <div class="nav-label">{{ "mainNavigation.finance" | translate }}</div>
      </div>
    </a>
    <a
      mat-list-item
      routerLink="./dgnb"
      routerLinkActive="is-active"
      class="dgnb-systems"
    >
      <div class="nav-item-content">
        <mat-icon>store</mat-icon>
        <div class="nav-label">{{ "mainNavigation.dgnb" | translate }}</div>
        <div class="nav-label second-row">
          {{ "mainNavigation.dgnb2" | translate }}
        </div>
      </div>
    </a>
    <a mat-list-item routerLink="./appConfig" routerLinkActive="is-active">
      <div class="nav-item-content">
        <mat-icon>settings</mat-icon>
        <div class="nav-label">
          {{ "mainNavigation.appConfig1" | translate }}
        </div>
        <div class="nav-label second-row">
          {{ "mainNavigation.appConfig2" | translate }}
        </div>
      </div>
    </a>
  </mat-nav-list>
  <mat-nav-list
    class="misc"
    [ngClass]="(isHandset$ | async) ? 'mobile' : 'desktop'"
    (click)="_authService.logout()"
  >
    <a mat-list-item class="logout">
      <div class="nav-item-content">
        <mat-icon>logout</mat-icon>
        <div class="nav-label">Ausloggen</div>
      </div>
    </a>
  </mat-nav-list>
</div>
