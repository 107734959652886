import {Injectable} from '@angular/core';
import {browserLocalPersistence, browserSessionPersistence, getAuth, setPersistence,} from "firebase/auth";
import {Router} from "@angular/router";
import {BehaviorSubject, lastValueFrom, map, Observable, of, switchMap, tap} from "rxjs";
import {
  Auth,
  authState,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  User,
  UserCredential,
} from '@angular/fire/auth';
import {SnackbarService} from "../snackbar/snackbar.service";
import {AdminUserService} from "../admin-user/admin-user.service";
import {User as AdminUser} from "@eeule/eeule-shared/src/types/index"
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
/**
 * Service to handle authentication related functionalities.
 */
export class AuthService {
  currentUserAsAdmin$: BehaviorSubject<AdminUser | null> = new BehaviorSubject<AdminUser | null>(null);
  loginMessageSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private _router: Router,
              private _auth: Auth,
              private _snackbarService: SnackbarService,
              private _adminUserService: AdminUserService,
              private _translateService: TranslateService) {

  }

  /**
   * Logs out the current user.
   * @returns {Promise<void>} A promise resolving to void.
   */
  async logout(): Promise<void> {
    return this._auth.signOut().then(async () => {
      this._snackbarService.showMessage("auth.loggedOut", "success", true);
      await this._router.navigate(['/login']);
    });
  }

  /**
   * Checks if the user is authenticated.
   * @returns {Observable<boolean>} An observable of boolean indicating the authentication state.
   */
  isAuthenticated(): Observable<boolean> {
    return authState(this._auth).pipe(
      switchMap(user => {
        if (!user?.uid) return of(false);
        return this._adminUserService.getAdminUser(user.uid).pipe(
          tap(adminUser => {
            this.currentUserAsAdmin$.next(adminUser?.data() as AdminUser || null)
          }),
          map(adminUser => {
            return !!adminUser?.data()
          }),
        )
      })
    );
  }
  // this.currentUserAsAdmin$.next(adminUser?.data() as AdminUser || null)
  /**
   * Retrieves the current user.
   * @returns {User | null} The current user, or null if no user is authenticated.
   */
  getUser(): User | null {
    return this._auth.currentUser;
  }

  /**
   * Logs in the user with the provided email and password.
   * @param {string | null | undefined} email - The user's email.
   * @param {string | null | undefined} password - The user's password.
   * @param {boolean} persistentLogin - The user's password.
   * @returns {Promise<UserCredential | void>} A promise resolving to the user credentials.
   */
  public async login(email: string | null | undefined,
                     password: string | null | undefined,
                     persistentLogin?: boolean): Promise<UserCredential | void> {
    if (!email || !password) {
      this._snackbarService.showErrorMessage("auth.noEmailOrPasswort"
        , true);
      return;
    }
    return setPersistence(getAuth(),
      persistentLogin ? browserLocalPersistence : browserSessionPersistence)
      .then(async () => {
        const userCredentials = await signInWithEmailAndPassword(getAuth(), email, password);
        if (!userCredentials.user?.uid) {
          await Promise.reject({code: 'auth/no-access'})
        }
        const adminUser
          = await lastValueFrom(this._adminUserService.getAdminUser(userCredentials.user.uid));
        if (!adminUser?.data()) {
          await Promise.reject({code: 'auth/no-access'});
        }
        this.loginMessageSubject$.next('')
        await this._router.navigateByUrl("portal");
        this._snackbarService.showMessage("auth.loggedIn", "success", true);
        return userCredentials;
      })
      .catch(async (error) => {
        switch (error.code) {
          case 'auth/invalid-credential': {
            const invalidCredentialsMessage: string = await lastValueFrom(this._translateService.get("auth.invalidCredential"));
            this.loginMessageSubject$.next(invalidCredentialsMessage)
            return;
          }
          case 'auth/no-access': {
            const noAccessMessage: string = await lastValueFrom(this._translateService.get("auth.noAccess"));
            this.loginMessageSubject$.next(noAccessMessage)
            await this.logout();
            return;
          }
          case 'auth/too-many-requests': {
            const tooManyRequestsMessage: string = await lastValueFrom(this._translateService.get("auth.noAccess"));
            this.loginMessageSubject$.next(tooManyRequestsMessage)
            return;
          }
          default:
            this._snackbarService.showErrorMessage("auth.defaultLoginError", true);
        }
      })
  }

  /**
   * Sends a password reset email to the specified email address.
   * @param {string} email - The email address to which the password reset email will be sent.
   * @returns {Promise<void>} A promise resolving to void.
   */
  async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(this._auth, email);
      this._snackbarService.showMessage("auth.passwordResetSuccessMessage", "success", true)
    } catch (error) {
      this._snackbarService.showErrorMessage("auth.passwordResetErrorMessage", true)
      throw new Error();
    }
  }
}
