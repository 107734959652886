import {Component} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListSubheaderCssMatStyler} from "@angular/material/list";
import {AsyncPipe, NgClass, NgOptimizedImage} from "@angular/common";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {NavigationService} from "../../services/navigation/navigation.service";
import {UserInformationComponent} from "../user-information/user-information.component";

@Component({
  selector: 'eeule-admin-page-header',
  standalone: true,
  imports: [
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatListSubheaderCssMatStyler,
    NgOptimizedImage,
    AsyncPipe,
    UserInformationComponent,
    NgClass
  ],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent {
  constructor(public _breakpointObserver: BreakpointObserver,
              public _navigationService: NavigationService) {
  }


  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
}
