import { Injectable } from '@angular/core';
import {FirebaseService} from "../firebase/firebase.service";
import {from, map, Observable} from "rxjs";
import {collection, doc, getDoc, QueryDocumentSnapshot, DocumentSnapshot, setDoc} from "firebase/firestore";
import {deleteDoc, getDocs} from "@angular/fire/firestore";
import {User} from "@eeule/eeule-shared/src/types/index";

@Injectable({
  providedIn: 'root'
})
/**
 * Service to interact with adminUser data in Firebase Firestore.
 */
export class AdminUserService {

  constructor(private _firebaseService: FirebaseService) { }

  /**
   * Sets adminUser data in Firestore.
   * @param {User} adminUser - The adminUser object to be set.
   * @returns {Observable<void>} An observable that completes when the operation is done.
   */
  setAdminUser(adminUser: User): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `adminUsers`, adminUser.id);
    return from(setDoc(docRef, adminUser));
  }

  /**
   * Retrieves all adminUsers from Firestore.
   * @returns {Observable<QueryDocumentSnapshot[]>} An observable emitting an array of query document snapshots.
   */
  getAllAdminUsers(): Observable<QueryDocumentSnapshot[]> {
    const docRef = collection(this._firebaseService.firestore, `adminUsers`);
    return from(getDocs(docRef)).pipe(map((snapshot) => snapshot.docs));
  }

  /**
   * Retrieves a specific adminUser from Firestore.
   * @param {string} id - The ID of the adminUser to retrieve.
   * @returns {Observable<DocumentSnapshot>} An observable emitting the document snapshot of the adminUser.
   */
  getAdminUser(id: string): Observable<DocumentSnapshot> {
    const docRef = doc(this._firebaseService.firestore, `adminUsers`, id);
    return from(getDoc(docRef));
  }

  /**
   * Deletes an adminUser from Firestore.
   * @param {string} id - The ID of the adminUser to delete.
   * @returns {Observable<void>} An observable that completes when the operation is done.
   */
  deleteAdminUser(id: string): Observable<void> {
    const docRef = doc(this._firebaseService.firestore, `adminUsers`, id);
    return from(deleteDoc(docRef));
  }
}
