import {Injectable} from '@angular/core';
import {Theme} from "../../../shared/types/theme-types";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private theme: Theme = localStorage.getItem("theme") as Theme || "dark-theme";
  themeSubject$: BehaviorSubject<Theme>
    = new BehaviorSubject<Theme>(this.theme);

  constructor() {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", this.theme)
    }
  }


  /**
   * Sets the application theme.
   * @param {Theme} theme - The theme to set.
   */
  setTheme(theme: Theme) {
    localStorage.setItem("theme", theme);
    this.theme = theme;
    this.themeSubject$.next(theme);
  }

  /**
   * Retrieves the current application theme.
   * @returns {Theme} The current theme.
   */
  getTheme(): Theme {
    return this.theme;
  }

}
