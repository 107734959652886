import {Component} from '@angular/core';
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatLabel, MatPrefix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {AuthService} from "../../core/services/auth/auth.service";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'eeule-admin-forgot-password',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    ReactiveFormsModule,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatPrefix,
    TranslateModule
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup<{ email: FormControl<string | null> }> = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  })

  constructor(public _authService: AuthService,
              private _formBuilder: FormBuilder) {
  }

  /**
   * Submits the forgot password form.
   */
  async submit() {
    if (this.forgotPasswordForm.valid) {
      const email = this.forgotPasswordForm.get('email')?.value;
      if (!email?.length) return;
      await this._authService.sendPasswordResetEmail(email);
    }
  }
}
