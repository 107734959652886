import {Component} from '@angular/core';
import {MatListItem, MatListItemTitle, MatNavList} from "@angular/material/list";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MatIcon} from "@angular/material/icon";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {AsyncPipe, NgClass} from "@angular/common";
import {AuthService} from "../../services/auth/auth.service";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'eeule-admin-main-navigation',
  standalone: true,
  imports: [
    MatListItem,
    MatNavList,
    RouterLink,
    MatIcon,
    MatListItemTitle,
    RouterLinkActive,
    AsyncPipe,
    NgClass,
    TranslateModule
  ],
  templateUrl: './main-navigation.component.html',
  styleUrl: './main-navigation.component.scss'
})
export class MainNavigationComponent {
  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private _breakpointObserver: BreakpointObserver,
              public _authService: AuthService) {
  }
}
