<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false"
               [ngClass]="{'handset' : (isHandset$ | async)}">
    <div class="drawer-inner-content">
      @if (isHandset$ | async) {
        <button
          type="button"
          aria-label="Toggle sidenav"
          class="btn-toggle accent-2 no-shadow"
          mat-mini-fab
          (click)="_navigationService.toggle()">
          <mat-icon aria-label="Side nav toggle icon">close</mat-icon>
        </button>
        <eeule-admin-user-information trigger="sidebar"/>
      }
      <eeule-admin-main-navigation class="h-100-percent"/>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <ng-content/>
  </mat-sidenav-content>
</mat-sidenav-container>
