import {CommonModule} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatButton, MatFabButton, MatMiniFabButton,} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatSidenavContainer, MatSidenavContent,} from '@angular/material/sidenav';
import {MatTooltip} from '@angular/material/tooltip';
import {RouterOutlet} from '@angular/router';
import {MainDrawerComponent} from './core/components/main-drawer/main-drawer.component';
import {PageHeaderComponent} from './core/components/page-header/page-header.component';
import {FirebaseService} from './core/services/firebase/firebase.service';
import {ThemeService} from './core/services/theme/theme.service';
import {themes} from "./shared/types/theme-types";
import {OverlayContainer} from "@angular/cdk/overlay";

@Component({
  selector: 'eeule-admin-root',
  standalone: true,
  imports: [
    CommonModule,
    MainDrawerComponent,
    MatButton,
    MatFabButton,
    MatIcon,
    MatMiniFabButton,
    MatSidenavContainer,
    MatSidenavContent,
    MatTooltip,
    PageHeaderComponent,
    RouterOutlet,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(_firebaseService: FirebaseService,
              public _themeService: ThemeService,
              private _overlayContainer: OverlayContainer) {
    _firebaseService.initialize();
  }

  ngOnInit(): void {
    this._themeService.themeSubject$.subscribe(theme => {
      themes.forEach(t => {
        this._overlayContainer.getContainerElement().classList.remove(t);
      })
      this._overlayContainer.getContainerElement().classList.add(theme);
    });
  }
}
