import {Component} from '@angular/core';
import {MainDrawerComponent} from "../core/components/main-drawer/main-drawer.component";
import {PageHeaderComponent} from "../core/components/page-header/page-header.component";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'eeule-admin-portal',
  standalone: true,
  imports: [
    MainDrawerComponent,
    PageHeaderComponent,
    RouterOutlet
  ],
  templateUrl: './portal.component.html',
  styleUrl: './portal.component.scss'
})
export class PortalComponent {
}
