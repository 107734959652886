import {Component} from '@angular/core';
import {MatCard, MatCardHeader, MatCardSubtitle, MatCardTitle} from "@angular/material/card";
import {MatButton} from "@angular/material/button";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthService} from "../core/services/auth/auth.service";
import {MatFormField, MatLabel, MatPrefix, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {AsyncPipe, NgClass, NgOptimizedImage} from "@angular/common";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatDialog} from "@angular/material/dialog";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {TranslateModule} from "@ngx-translate/core";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";

type LoginForm = {
  email: FormControl<string | null>,
  password: FormControl<string | null>,
  persistentLogin: FormControl<boolean | null>
}

@Component({
  selector: 'eeule-admin-login',
  standalone: true,
  imports: [
    MatCard,
    MatButton,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    NgOptimizedImage,
    MatSuffix,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    AsyncPipe,
    MatPrefix,
    MatCheckbox,
    TranslateModule,
    NgClass
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  loginForm: FormGroup<LoginForm> = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    persistentLogin: [false]
  })

  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(public _authService: AuthService,
              private _formBuilder: FormBuilder,
              private _dialog: MatDialog,
              private _breakpointObserver: BreakpointObserver) {
  }

  /**
   * Submits the login form.
   */
  async submit() {
    await this._authService.login(this.loginForm.value.email, this.loginForm.value.password, !!this.loginForm.value.persistentLogin);
  }

  /**
   * Opens the forgot password dialog.
   */
  openForgotPasswordDialog() {
    this._dialog.open(ForgotPasswordComponent, {width: "400px"});
  }

}
