import {Component, Input} from '@angular/core';
import {AsyncPipe, NgClass, NgOptimizedImage} from "@angular/common";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Observable, of, switchMap} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {AuthService} from "../../services/auth/auth.service";
import {ThemeService} from "../../services/theme/theme.service";
import {MatSlideToggle, MatSlideToggleChange} from "@angular/material/slide-toggle";
import {MatTooltip} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";
import {UserService} from "../../services/user/user.service";
import {User} from "@eeule/eeule-shared/src/types/index"

@Component({
  selector: 'eeule-admin-user-information',
  standalone: true,
  imports: [
    NgOptimizedImage,
    AsyncPipe,
    NgClass,
    MatIcon,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatSlideToggle,
    MatTooltip,
    TranslateModule
  ],
  templateUrl: './user-information.component.html',
  styleUrl: './user-information.component.scss'
})
export class UserInformationComponent {
  @Input() trigger: 'sidebar' | 'page-header' = 'page-header';

  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  currentUser$ = this._authService.currentUserAsAdmin$.pipe(
    switchMap((user: User | null) => {
      if (!user) return of(null);
      return this._userService.getUser(user?.id)
    }),
    map(user => {
      return user?.data() as User
    }),
  );

  constructor(public _authService: AuthService,
              public _userService: UserService,
              public _themeService: ThemeService,
              private _breakpointObserver: BreakpointObserver) {
  }

  switchTheme(ev: MatSlideToggleChange) {
    this._themeService.setTheme(ev.checked ? 'dark-theme' : 'default-theme');
  }
}
